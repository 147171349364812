const firebaseConfig = {
    apiKey: "AIzaSyAwjksHTo8O7gF8IwskxDZI14uNYLfROeU",
    authDomain: "blip-landingpage.firebaseapp.com",
    projectId: "blip-landingpage",
    storageBucket: "blip-landingpage.appspot.com",
    messagingSenderId: "684735071085",
    appId: "1:684735071085:web:f899bf822c8ff41097d064",
    measurementId: "G-KKQPVP15KN"
  };

  export default firebaseConfig ;